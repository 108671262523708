import React from 'react';

function bannerBOB() {
  return (
    <div
        className="home-banner"
        style={{
          backgroundImage: 'url(' + process.env.BANNER_BACKGROUND + ')',
        }}>
      <div className="home-banner__inner">
        <div className="home-banner__header">
          AMAZING BEER
          <br />
          MODERN BREWERY
        </div>
        <div className="home-banner__subheader">
          Buffalo Olde Brewery is a one-of-a-kind destination brewery! Amazing Craft Beer, Classic Buffalo Food,
          Speciality Cocktails, Huge Outdoor Patio, Modernized Early 20th Century Bar, Unique History. A must stop for
          anyone visiting the Buffalo area.
        </div>
      </div>
    </div>
  );
}

export default bannerBOB;
