import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment-mini';
import Throbber from '../throbber';
import AccountManager from '../../managers/Account';
import { getBlockchainType, getParameterByName } from '../../managers/Helpers';

const EDropLanding = ({ transferToken }) => {
  const [transferCode, setTransferCode] = useState(null);
  const [verifiedCode, setVerifiedCode] = useState(null);
  const [previewData, setPreviewData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorData, setErrorData] = useState(null);
  const optOut = () => {
    sessionStorage.removeItem(transferToken);
    window.location.href = '/';
  };
  const handleInputChange = (e) => {
    setTransferCode(e.target.value);
  };

  const clearAllTransferCookies = () => {
    Object.entries(sessionStorage).map((obj) => {
      const key = obj[0];
      if (key.includes('NFT_TRANSFER')) {
        sessionStorage.removeItem(key);
      }
      return true;
    });
  };

  const prePopulateRegData = () => {
    const registerObj = {};
    
    if (getParameterByName('f')) {
      registerObj.firstName = getParameterByName('f');
    }
    if (getParameterByName('l')) {
      registerObj.lastName = getParameterByName('l');
    }
    if (getParameterByName('e')) {
      registerObj.email = getParameterByName('e');
    }

    if (registerObj.firstName || registerObj.lastName || registerObj.email) {
      sessionStorage.setItem('ETHOS_REGISTRATION', JSON.stringify(registerObj));
    }
  };

  const getTransferCodePreview = async (previewCode) => {
    setIsLoading(true);
    let alreadyClaimed = false;
    if (localStorage.getItem('NFT_TOKEN_TRANSFERRED_' + previewCode)) {
      alreadyClaimed = true;
    }
    if (!alreadyClaimed) {
      const preview = await AccountManager.getTransferDetails(null, previewCode);
      if (preview && preview.success === false) {
        setPreviewData(null);
        setErrorData(preview);
      } else if (preview) {
        setPreviewData(preview);
        setVerifiedCode(previewCode);
        sessionStorage.setItem('NFT_TRANSFER_' + previewCode, previewCode);
        // check for pre pop details
        prePopulateRegData();
      } else {
        toast.error('Transfer code not found. Please check your code and try again.');
        setPreviewData(null);
        clearAllTransferCookies();
      }
    } else {
      toast.error('This NFT token has already been claimed.');
      setPreviewData(null);
      clearAllTransferCookies();
    }
    setIsLoading(false);
  };

  const enterCode = async () => {
    // check if the code exists
    await getTransferCodePreview(transferCode);
  };

  useEffect(() => {
    document.body.classList.add('-account-background');
    document.body.style.backgroundImage = 'url(' + process.env.ACCOUNT_BACKGROUND + ')';
  }, []);
  useEffect(() => {
    setTransferCode(transferToken);
    if (transferToken) {
      getTransferCodePreview(transferToken);
    } else {
      setIsLoading(false);
    }
  }, [transferToken]);

  return (
    <div className="account-container__outer">
      <div className="account-container__inner">
        <div className="account-box -center">
          {previewData && previewData.asset
            ? (
              <div className="account-heading">
                Claim your
                {' '}
                {previewData
                  ? <span>{previewData.assetVariation && previewData.assetVariation.variationName ? previewData.assetVariation.variationName : previewData.asset.name}</span>
                  : <span>digital asset</span>}
              </div>
            )
            : (
              <div className="account-heading">
                Claim your digital asset
              </div>
            )}
          {!errorData
          && (
          <div className="account-subheading">
            Create an account or log in to claim this free digital asset.
          </div>
          )}
          {errorData ? (
            <div>
              <div className="account-subheading">
                {errorData.message}
              </div>
            </div>
          )
            : (
              <div className="account-verify-token">
                {!verifiedCode
                  ? (
                    <div className="account-verify-form">
                      <input className="input-verify-token" placeholder="Enter transfer code" value={transferCode} onChange={(e) => handleInputChange(e)} />
                      <div className="account-verify-cta">
                        <button
                        onClick={() => enterCode()}
                        type="button">
                          Enter code
                        </button>
                      </div>
                    </div>
                  )
                  : (
                    <div>
                      <div className="input-code-verified">
                        Claim code verified
                      </div>
                      <img className="account-verify-token__check" src="https://ipfs.ethosnft.com/ethos/icon-success.svg" alt="" />
                    </div>
                  )}
              </div>
            )}
          <div className={isLoading ? 'edrop-preview' : 'edrop-no-preview'}>
            {isLoading
              && <Throbber throbberText="Loading preview..." />}
            {previewData
            && (
              <div>
                {previewData && previewData.asset
                  ? (
                    <div className="transfer-preview">
                      <div className="transfer-preview__image">
                        {previewData.assetVariation.mediaFileUrl.includes('.mp4')
                          ? (
                            <video autoPlay="autoplay" playsInline loop muted poster={previewData.assetVariation.thumbnailUrl}>
                              <source src={previewData.assetVariation.mediaFileUrl} type="video/mp4" />
                              <track
                                  default
                                  kind="captions"
                                  srcLang="en"
                                  src="" />
                              Your browser does not support the video tag.
                            </video>
                          )
                          : <img src={previewData.assetVariation && previewData.assetVariation.thumbnailUrl ? previewData.assetVariation.thumbnailUrl : previewData.asset.mediaLocalIPFS} alt="NFT Preview" />}
                      </div>
                      <div className="transfer-preview__details">
                        <div className="transfer-preview__details--inner">
                          <h3 id="edropName" className="card-title">{previewData.assetVariation && previewData.assetVariation.variationName ? previewData.assetVariation.variationName : previewData.asset.name}</h3>
                          <p>
                            Blockchain:
                            {' '}
                            {getBlockchainType(previewData.asset.blockchain)}
                          </p>
                          <p>
                            Date created:
                            {' '}
                            {moment(previewData.asset.dateCreated).format('MMMM DD, YYYY')}
                          </p>
                          <p>

                            {previewData.asset.assetDescription}
                          </p>
                          {/* <p dangerouslySetInnerHTML={{ __html: previewData.asset.assetHTMLDesc }}></p> */}

                        </div>
                      </div>
                    </div>
                  )
                  : (
                    <div className="transfer-preview -no-flex">
                      <div>
                        {previewData.senderFirstName}
                        {' '}
                        {' '}
                        {' '}
                        {previewData.senderLastName}
                        {' '}
                        has sent you an NFT.
                      </div>
                      <blockquote className="form-blockquote__block">
                        {previewData.message}
                      </blockquote>
                    </div>
                  )}
                
              </div>
            )}
          </div>
          {verifiedCode
          && (
          <div>
            <div>Log in or sign up below.</div>
            <div className="form-cta">
              <Link
            to="/login"
            className="button">
                Log in
              </Link>
          &nbsp;
              <Link
            to="/register"
            className="button">
                Create account
              </Link>
            </div>
          </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default EDropLanding;
