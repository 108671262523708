import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment-mini';
import AccountManager from '../../managers/Account';
import Throbber from '../throbber';
import TransferToggle from './transferToggle';
import {
  getBlockchainType, truncateString, getUtilityType, getScanUrl,
} from '../../managers/Helpers';
import Gallery from './gallery';
import TransferNFT from './transferNFT';
import TransferWallet from './transferWallet';
import SimpleModal from '../modal/modal';
import AzureMediaPlayer from '../video/azureMediaPlayer';
import UtilityDisplay from '../utility/utilityDisplay';

export default function CollectionDetails(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [nft, setNft] = useState(null);
  const [showPassModal, setShowPassModal] = useState(false);
  const [isPassGenerating, setIsPassGenerating] = useState(true);
  const [nftPass, setNftPass] = useState(null);
  const [showFileViewer, setFileViewerState] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [walletTransfer, setWalletTransferModal] = useState(false);
  const [transferSelector, toggleTransferSelectorModal] = useState(false);
  const [transfer, setUserTransferModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isModalLoading, setModalLoadingState] = useState(false);
  const [selectedTab, setSelectedTab] = useState('description');

  const flowscanUrl = process.env.BLOCKCHAIN_ENVIRONMENT === 'test' ? 'https://testnet.flowview.app/account/' : 'https://www.flowview.app/account/';
  const polyscanUrl = process.env.BLOCKCHAIN_ENVIRONMENT === 'test' ? 'https://mumbai.polygonscan.com/' : 'https://polygonscan.com/';

  const { nftId } = useParams();

  const setTab = (tab, e) => {
    e.preventDefault();
    setSelectedTab(tab);
  };

  const closeFileViewer = () => {
    setFileViewerState(false);
    setSelectedVideo(null);
    setSelectedFile(null);
  };

  const toggleTransferModal = (showModal, nft) => {
    toggleTransferSelectorModal(showModal);
    setSelectedFile(nft);
  };

  const showUserTransferModal = () => {
    toggleTransferSelectorModal(false);
    setUserTransferModal(nft);
  };

  const showWalletTransferModal = () => {
    toggleTransferSelectorModal(false);
    setWalletTransferModal(nft);
  };

  const closeWalletTransferModal = (doUpdate) => {
    setWalletTransferModal(false);
    if (doUpdate) {
      // getCollection();
    }
  };

  const transferSuccessful = (walletAddress) => {
    console.log(walletAddress);
    console.log('NFT transferred successfully');
    nft.withdrawAddress = walletAddress;
  };

  const confirmTransfer = () => {
    nft.hasPendingTransfer = true;
    setUserTransferModal(false);
  };

  const cancelTransfer = (newItem) => {
    setShowModal(true);
  };

  const confirmCancel = async () => {
    nft.hasPendingTransfer = false;
    setModalLoadingState(true);

    const payload = {
      nftId: nft.nftId,
      creatorId: process.env.CREATOR_ID,
    };

    const cancelled = await AccountManager.cancelTransfer(AccountManager.getToken(), payload);

    if (cancelled && cancelled.success) {
      toast.success('Successfully cancelled transfer.');
    } else {
      toast.error('Oops. Something went wrong. Please try again later.');
    }
    setModalLoadingState(false);
    setShowModal(false);
    // updateCollection();
  };

  const togglePassModal = async (nft) => {
    setShowPassModal(!showPassModal);
    setIsPassGenerating(true);
    console.log(nft.nftId);
    const payload = {
      nftId: nft.nftId,
    };
    const applePass = await AccountManager.generateApplePass(AccountManager.getToken(), payload).then((response) => {
      // Create blob link to download
      console.log('=== generating apple pass ===');
      console.log(response);
      // const url = window.URL.createObjectURL(
      //   new Blob([response]),
      // );
      // setNftPass(url);
      if (response && response.success) {
        toast.success('Successfully created Apple pass.');
        setNftPass(response.passUri);
      } else {
        toast.error('Error creating Apple pass. Please try again later.');
        setShowPassModal(false);
      }
      setIsPassGenerating(false);
    });
    console.log({ applePass });
  };

  const loadVideo = async (videoUrl) => {
    setFileViewerState(true);
    setSelectedVideo(videoUrl);
    console.log(videoUrl);
  };

  const launchPDFViewer = (e) => {
    e.preventDefault();
    setFileViewerState(true);
    setSelectedFile(nft);
  };

  const getDetails = async (nftId) => {
    // get the collection from the server
    setIsLoading(true);
    AccountManager.getNftDetails(AccountManager.getToken(), nftId).then((res) => {
      setNft(res.nft);
      console.log('=== blockchain ===');
      console.log(getScanUrl(res.nft));
      setIsLoading(false);
    }).catch((error) => {
      console.log(error);
      setIsLoading(false);
      toast.error('Oops. There was a problem getting your collection data. Please try again later.');
    });
  };

  useEffect(() => {
    getDetails(nftId);
  }, []);

  return (
    <div>
      <SimpleModal isOpen={showModal} onBeforeClose={() => setShowModal(false)}>
        {isModalLoading
          && <Throbber throbberText="Cancelling transfer! Please stand by..." />}
        Are you sure you want to cancel this transfer?
        <div className="form-cta">
          <button
            className="button button-small button-confirm -no-margin"
            type="submit"
            onClick={(e) => confirmCancel(e)}>
            Confirm Cancel
          </button>
          &nbsp;
          <button
            className="button button-small -no-margin"
            type="submit"
            onClick={() => setShowModal(false)}>
            Back
          </button>
        </div>
      </SimpleModal>
      <SimpleModal isOpen={showPassModal} onBeforeClose={() => setShowPassModal(false)}>
        {showPassModal && (
        <div>
          {isPassGenerating
              && <Throbber throbberText="Generating Apple Pass! Please stand by..." />}
          <div className="transfer-success register-results">
            <img className="register-results__icon" src="https://ipfs.ethosnft.com/ethos/icon-success.svg" alt="" />
            <div className="register-results__header">Success!</div>
            <p>
              Apple pass for this NFT has been created. Click the Add to wallet button below to add this to your wallet.
            </p>
          </div>
          <div className="form-cta -align-center">
            <a href={nftPass} className="button button-apple-wallet">
              <img src="http://ipfs.ethosnft.com/ethos/button-apple-wallet.png" alt="Add to Apple Wallet" />
            </a>
          </div>
        </div>
        )}
      </SimpleModal>
      {walletTransfer
      && (
      <div>
        <TransferWallet
          nft={walletTransfer}
          onClose={(doUpdate) => closeWalletTransferModal(doUpdate)}
          onSuccess={(walletAddress) => transferSuccessful(walletAddress)} />
      </div>
      )}
      {transfer
      && (
      <div>
        <TransferNFT nft={transfer} onClose={() => setUserTransferModal(false)} onSuccess={() => confirmTransfer()} />
      </div>
      )}
      {transferSelector
      && (
      <div>
        <TransferToggle
          onTransferToUser={() => showUserTransferModal()}
          onTransferToWallet={() => showWalletTransferModal()}
          onClose={() => toggleTransferSelectorModal(false)} />
      </div>
      )}
      {showFileViewer
        && (
        <div className="file-viewer">
          <div className={selectedVideo ? 'file-viewer__inner -video' : 'file-viewer__inner'}>
            <a className="modal__close -outside-right" href="#" onClick={() => closeFileViewer()}>
              <img src="https://ipfs.ethosnft.com/global/icon-close-dark.svg" alt="Close modal" />
            </a>

            {selectedFile && selectedFile.asset
            && (
              <Gallery creator={selectedFile.creator} />
            )}

            {selectedVideo
            && <AzureMediaPlayer src={selectedVideo} />}

            {/* <video data-setup='{"nativeControlsForTouch": false}' className="azuremediaplayer amp-default-skin" controls controlslist="nodownload" autoPlay="autoplay" loop muted>
              <source src={selectedVideo} type="video/mp4" />
              <track
                default
                kind="captions"
                srcLang="en"
                src="" />
              Your browser does not support the video tag.
            </video> */}

          </div>
        </div>
        )}
      <div className="account-box -account-section -nft-details">
        {isLoading
            && <Throbber throbberText="Loading NFT details! Please stand by..." />}
        {nft
        && (
        <div>
          <h2 className="account-heading__h2">
            {nft.assetVariation ? nft.assetVariation.variationName : nft.asset.name}
          </h2>
          <p>
            Collection:&nbsp;
            {nft.asset.name}
          </p>
          <div className="nft-details__asset-container">
            <div className="nft-details__asset">
              {nft.imageUrl.includes('.mp4')
                ? (
                  <div>
                    <video autoPlay="autoplay" loop muted>
                      <source src={nft.imageUrl} type="video/mp4" />
                      <track
                        default
                        kind="captions"
                        srcLang="en"
                        src="" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                )
                : (
                  <div>
                    {nft.asset.mediaUrl && nft.asset.mediaUrl.includes('.pdf')
                      ? <a href="#" onClick={(e) => launchPDFViewer(e)}><img src={nft.asset.mediaLocalIPFS} alt={nft.assetVariation ? nft.assetVariation.variationName : nft.asset.name} /></a>
                      : <img src={nft.imageUrl} alt={nft.assetVariation ? nft.assetVariation.variationName : nft.asset.name} />}
                  </div>
                )}
            </div>
          </div>
          {nft.nftUtility.length > 0 || nft.collectionUtility.length > 0
            ? (
              <div className="nft-details__tabs">
                <div className={selectedTab === 'description' ? 'nft-details__tab -active' : 'nft-details__tab'}>
                  <a href="#" onClick={(e) => setTab('description', e)}>DESCRIPTION</a>
                </div>
                <div className={selectedTab === 'benefits' ? 'nft-details__tab -active' : 'nft-details__tab'}>
                  <a href="#" onClick={(e) => setTab('benefits', e)}>BENEFITS</a>
                </div>
              </div>
            )
            : (
              <div className="nft-details__tabs">
                <div className="nft-details__tab -full-width -active">
                  DESCRIPTION
                </div>
              </div>
            )}
          <div>
            <div className={selectedTab === 'description' ? 'nft-details__tab-content -active' : 'nft-details__tab-content'}>
              <div className="nft-details__tab-inner">
                <div className="nft-details__slot--inner">
                  {nft.asset.assetDescription}
                </div>
                <div
                    className="nft-details__slot--html"
                    dangerouslySetInnerHTML={{ __html: nft.asset.assetHTMLDesc }}>
                </div>
                <div className="nft-details__details-box">
                  <ul>
                    <li>
                      Blockchain:&nbsp;
                      {getBlockchainType(nft.asset.blockchain)}
                    </li>
                    <li>
                      Minting Status:&nbsp;
                      {!nft.contractAddress
                        ? (
                          <strong className="collection-details__status -pending">
                            Pending
                          </strong>
                        )
                        : (
                          <strong className="collection-details__status -pending">
                            Minted
                          </strong>
                        )}

                    </li>
                    <li>
                      TokenId:&nbsp;
                      {nft.tokenId || nft.number}
                    </li>
                    {nft.contractAddress
                      && (
                      <li>
                        Contract Address:&nbsp;
                        <a className="collection-details__status -pending" href={getScanUrl(nft)} target="_blank" rel="noreferrer">{truncateString(nft.contractAddress, 20)}</a>
                      </li>
                      )}
                    {nft.withdrawAddress
                    && (
                    <li>
                      Owner:
                      &nbsp;
                      {truncateString(nft.withdrawAddress, 20)}
                    </li>
                    )}
                    {nft.txn
                      && (
                      <li>
                        Date Aquired:&nbsp;
                        {moment(nft.txn.dateStamp).format('MMMM DD, YYYY')}
                      </li>
                      )}
                    {nft.txn
                      && (
                      <li>
                        Amount Paid:&nbsp;
                        $
                        {nft.txn.totalAmount}
                      </li>
                      )}
                  </ul>
                </div>
              </div>
            </div>
            <div className={selectedTab === 'benefits' ? 'nft-details__tab-content -active' : 'nft-details__tab-content'}>
              {nft.nftUtility.length > 0 || nft.collectionUtility.length > 0
              
                ? (
                  <UtilityDisplay
                    nftId={nft.nftId}
                    nftUtility={nft.nftUtility}
                    collectionUtility={nft.collectionUtility}
                    onPlay={(videoUrl) => loadVideo(videoUrl)} />
                )
                : <div className="nft-details__tab-inner">This digital asset does not have any utility</div>}
              {/*
                    <ul>
                      <li><a href="#" onClick={(e) => loadVideo(e)}>Greatness Calling Episode 1</a></li>
                      <li><a href="#" onClick={(e) => loadVideo(e)}>Greatness Calling Episode 2</a></li>
                      <li><a href="#" onClick={(e) => loadVideo(e)}>Greatness Calling Episode 3</a></li>
                      <li><a href="#" onClick={(e) => loadVideo(e)}>Greatness Calling Episode 4</a></li>
                      <li><a href="#" onClick={(e) => loadVideo(e)}>Greatness Calling Episode 5</a></li>
                    </ul> */}
            </div>
            
          </div>

          <div className="nft-details__footer">
            {nft.hasPendingTransfer
              && <button onClick={() => cancelTransfer(nft)} className="button-nav -cancel -block" type="button">Cancel Transfer</button>}
            {!nft.withdrawAddress
              && <button onClick={() => toggleTransferModal(true)} className="button-nav -block -not-last" type="button">Transfer NFT</button>}
            {nft.passTemplate
                    && <button onClick={() => togglePassModal(nft)} className="button-nav -block" type="button">Add to Apple Wallet</button>}
            {nft.contractAddress
                    && <a className="button button-nav -block" href={getScanUrl(nft)} target="_blank" rel="noreferrer">View on Blockchain</a>}
          </div>
          
        </div>
        )}
        {/* <div className="account-subheading">
          View your digital assets here dummy
        </div> */}
      </div>
    </div>
  );
}
