import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Countdown from 'react-countdown';
import moment from 'moment-mini';
import { Link } from 'react-router-dom';
import { urlEncode } from '@sentry/utils';

class HomeHeroBOB extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countdownData: null,
      friendlyDate: null,
      purchaseResult: null,
    };
  }

  setHeroHeight = () => {
    const hero = document.getElementById('landingHero');
    if (hero) {
      hero.style.height = window.innerHeight + 'px';
    }
  }

  parseCountdown = (date) => {
    this.setState({
      countdownData: moment(date).unix() * 1000,
      friendlyDate: moment(date).format('ha  on dddd, MMMM D, YYYY'),
    });
  }

  componentDidMount() {
    const { data } = this.props;
    const that = this;
    if (process.env.BANNER_TYPE !== 'slim') {
      this.setHeroHeight();
      window.addEventListener('resize', that.setHeroHeight);
    }
    that.parseCountdown(data.drop.dateOpen);
    const hasPurchased = localStorage.getItem(process.env.PURCHASE_COOKIE + data.drop.dropId);
    this.setState({
      purchaseResult: hasPurchased ? 'success' : null,
    });
  }

  learnMore = (e) => {
    const { onLearnMore } = this.props;
    if (e) {
      e.preventDefault();
    }
    onLearnMore();
  }

  buyNow = (e) => {
    const { onBuyNow } = this.props;
    e.preventDefault();
    this.learnMore();
    onBuyNow();
  }

  goToFaq = (e) => {
    const { onFaqButton } = this.props;
    e.preventDefault();
    onFaqButton();
  }

  render() {
    const { type } = this.props;
    const { countdownData, purchaseResult } = this.state;
    const renderer = ({
      days, hours, minutes, seconds, completed,
    }) => {
      if (completed) {
        window.location.href = '/';
      }
      // Render a countdown
      return (
        <div>
          <div className="countdown-item">
            {days}
            {' '}
            <span>days</span>
          </div>
          <div className="countdown-item">
            {hours}
            {' '}
            <span>hours</span>
          </div>
          <div className="countdown-item">
            {minutes}
            {' '}
            <span>mins</span>
          </div>
          <div className="countdown-item">
            {seconds}
            {' '}
            <span>secs</span>
          </div>
        </div>
      );
    };
    return (
      <div>
        <div
          id="landingHero"
          className={process.env.BANNER_TYPE === 'slim' ? 'landing-hero -fixed' : 'landing-hero'}
          style={{
            backgroundImage: 'url(' + process.env.HERO_BACKGROUND + ')',
          }}>
          <video className="hero-video" autoPlay="autoplay" playsInline loop muted>
            <source src={process.env.HERO_BACKGROUND_VIDEO} type="video/mp4" />
            <track
            default
            kind="captions"
            srcLang="en"
            src="" />
            Your browser does not support the video tag.
          </video>
          <div className="landing-hero__inner">
            {type === 'countdown' && process.env.HERO_LOGO
            && <div className="hero-title__logo"><img src={process.env.HERO_LOGO} alt="" /></div>}
            <div className="landing-hero__text -center-vert">
              {/* TODO: make this part of the env file */}
              <div className="hero-title__accent">GET YOUR</div>
              <h1 className="hero-title">LIMITED EDITION</h1>
              <div className="hero-title__accent">BOTTLE CAP DIGITAL MEMBERSHIP</div>
              <h2 className="hero-subtitle">{process.env.HERO_SUBTITLE}</h2>
              <div className="hero-header-cta">
                <a href="#" className="button" onClick={(e) => this.learnMore(e)}>Get It Now</a>
              </div>
            </div>
          </div>
        </div>
        <div className="featured-drop-bob__outer">
          <div className="featured-drop__bob">
            <h2 className="countdown-header -no-margin">You’re getting $75+ in value for just&nbsp;$50.</h2>
            {/* <div className="hero-header-cta">
              <a href="#" className="button" onClick={(e) => this.learnMore(e)}>Learn More</a>
            </div> */}
            <p className="hero-description">
              There are only 100 NFT memberships available, grab yours before they disappear.
            </p>
            <div className="feature-tiles">
              <div className="feature-tiles__item">
                <div className="featture-tiles__item--inner">
                  <img src="https://ipfs.ethosnft.com/thebuffalooldebrewery/icons/icon-food-credit.svg" alt="$75 Food and Beverage Credit" />
                  <div>$75 Food and Beverage Credit</div>
                </div>
              </div>
              <div className="feature-tiles__item">
                <div className="featture-tiles__item--inner">
                  <img src="https://ipfs.ethosnft.com/thebuffalooldebrewery/icons/icon-exclusive.svg" alt="Exclusive Brewery Experiences" />
                  Exclusive Brewery Experiences
                </div>
              </div>
              <div className="feature-tiles__item">
                <div className="featture-tiles__item--inner">
                  <img src="https://ipfs.ethosnft.com/thebuffalooldebrewery/icons/icon-lovejoy.svg" alt="Own a piece of Lovejoy’s future" />
                  Own a piece of Lovejoy’s future
                </div>
              </div>
              <div className="feature-tiles__item">
                <div className="featture-tiles__item--inner">
                  <img src="https://ipfs.ethosnft.com/thebuffalooldebrewery/icons/icon-apple-wallet.svg" alt="Add it to your Apple wallet" />
                  Add it to your Apple wallet
                </div>
              </div>
            </div>
            <div className="feature-tiles__cta">
              {!purchaseResult
                && <a href="#" className="button" onClick={(e) => this.buyNow(e)}>Buy Now</a>}
              
            </div>
            {type === 'countdown'
                && (
                <div className="hero-countdown__inline">
                  {countdownData
                  && (
                  <Countdown
                  date={countdownData}
                  renderer={renderer} />
                  )}
                </div>
                )}
            {/* {type === 'countdown'
                  ? <a href="#" className="button-hero" onClick={(e) => this.learnMore(e)}>Learn More</a>
                  : <Link className="button-hero" to={'/drop/' + data.drop.dropId}>View Details</Link>} */}
          </div>
        </div>
      </div>
    );
  }
}

HomeHeroBOB.propTypes = {
  data: PropTypes.objectOf(PropTypes.object),
  type: PropTypes.string,
  onLearnMore: PropTypes.func,
  onFaqButton: PropTypes.func,
  onBuyNow: PropTypes.func,
};

export default HomeHeroBOB;
