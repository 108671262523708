const isIOS = function () {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod',
  ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
};

const getParameterByName = function (name, url) {
  let newName = name;
  let newUrl = url;
  if (!url) newUrl = window.location.href;
  newName = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + newName + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(newUrl);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

const getBlockchainType = function (chainId) {
  let chainname = 'Flow';
  if (!chainId) {
    chainname = 'Flow';
  } else if (chainId === 0) {
    chainname = 'Flow';
  } else if (chainId === 1) {
    chainname = 'Ethereum';
  } else if (chainId === 2) {
    chainname = 'Polygon';
  }
  return chainname;
};

const getUtilityType = function (type) {
  let utilityName = 'Coupon Voucher';
  if (!type || type === 1) {
    utilityName = 'Coupon Voucher';
  } else if (type === 2) {
    utilityName = 'Video Content';
  } else if (type === 3) {
    utilityName = 'Gated Commerce';
  } else if (type === 4) {
    utilityName = 'Generic Utility';
  }
  
  return utilityName;
};

const truncateString = function (ogString, length) {
  let len;
  if (!length) {
    len = 100;
  } else {
    len = length;
  }
  return ogString.substring(0, len) + '...';
};

const getScanUrl = function (nft) {
  const flowscanUrl = process.env.BLOCKCHAIN_ENVIRONMENT === 'test' ? 'https://testnet.flowview.app/account/' : 'https://www.flowview.app/account/';
  const polyscanUrl = process.env.BLOCKCHAIN_ENVIRONMENT === 'test' ? 'https://mumbai.polygonscan.com/' : 'https://polygonscan.com/';
  const ethscanUrl = process.env.BLOCKCHAIN_ENVIRONMENT === 'test' ? 'https://goerli.etherscan.io/' : 'https://etherscan.io/';
  let scanLink;

  if (nft.asset.blockchain === 0) {
    scanLink = flowscanUrl + nft.contractAddress + '/collection';
  } else if (nft.asset.blockchain === 1) {
    scanLink = ethscanUrl + 'token/' + nft.contractAddress + '?a=' + nft.tokenId;
  } else if (nft.asset.blockchain === 2) {
    scanLink = polyscanUrl + 'token/' + nft.contractAddress + '?a=' + nft.tokenId;
  } else {
    scanLink = flowscanUrl + nft.contractAddress + '/collection';
  }
  return scanLink;
};

const getScanName = function (blockchainId) {
  let scannerName;
  if (blockchainId === 0) {
    scannerName = 'flowscan';
  } else if (blockchainId === 1) {
    scannerName = 'etherscan';
  } else if (blockchainId === 2) {
    scannerName = 'polyscan';
  }
  return scannerName;
};

export { isIOS };
export { getParameterByName };
export { getUtilityType };
export { getBlockchainType };
export { truncateString };
export { getScanUrl };
export { getScanName };
