import React from 'react';

function footerBOB() {
  return (
    <div className="footer footer-bob">
      <div className="footer-bob__inner">
        <div className="slot-logo">
          <a href={process.env.CLIENT_WEBSITE} target="_blank" rel="noreferrer"><img src={process.env.LARGE_LOGO} width={process.env.LARGE_LOGO_WIDTH} alt="ethos" /></a>
        </div>
        <div className="slot-links">
          <div className="slot-inner">
            <div className="slot-icons__header">
              Follow Us Online
            </div>
            <div className="slot-icons">
              <a href="https://www.facebook.com/buffalooldebrewery/" target="_blank" rel="noreferrer"><img src="https://ipfs.ethosnft.com/thebuffalooldebrewery/icons/logo-facebook.svg" alt="twitter" /></a>
              <a href="https://www.instagram.com/buffalooldebrewery" target="_blank" rel="noreferrer"><img src="https://ipfs.ethosnft.com/thebuffalooldebrewery/icons/logo-instagram.svg" alt="twitter" /></a>
              <a href="https://www.linkedin.com/company/buffalo-olde-brewery" target="_blank" rel="noreferrer"><img src="https://ipfs.ethosnft.com/thebuffalooldebrewery/icons/logo-linkedin.svg" alt="twitter" /></a>
            </div>
          </div>
        </div>
        <div className="slot-cta">
          <div className="slot-inner -align-right">
            <a href="https://www.thebuffalooldebrewery.com/" target="_blank" rel="noreferrer" className="button">Return to Home Page</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default footerBOB;
